.wrapper {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  background: rgb(9, 93, 141);
  top: 54px;
  left: -67px;
}

.profile-pic {
  text-align: center;
  margin-bottom: 2.5vw;
}
.addUesr img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background: white;
}
input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.wrapper i {
  color: white;
  font-size: 1rem;
  margin: 0.8rem;
}
