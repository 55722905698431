.card {
  box-shadow: 0px 4px 20px -6px #ccc;
  border: 1px solid #ddd;
  padding: 10px;
}
.body {
  padding-left: 220px;
}
@media (max-width: 576px) {
  .body {
    padding-left: 0;
  }
}
