.not_found {
  padding-top: 15%;
}
.page-404 .outer .middle .inner .inner-circle:hover span {
  color: #39bbdb;
}
.page-404 .outer .middle .inner .inner-circle i {
  font-size: 5em;
  line-height: 1em;

  float: right;

  width: 1.6em;
  height: 1.6em;
  margin-top: -0.7em;
  margin-right: -0.5em;
  padding: 20px;

  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  text-align: center;

  color: #f5f5f5 !important;
  border-radius: 50%;
  background-color: #39bbdb;
  box-shadow: 0 0 0 15px #f0f0f0;
}
.page-404 .outer .middle .inner .inner-circle span {
  font-size: 11em;
  font-weight: 700;
  line-height: 1.2em;

  display: block;

  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  text-align: center;

  color: #e0e0e0;
}
.page-404 .outer .middle .inner .inner-status {
  font-size: 20px;

  display: block;

  margin-top: 20px;
  margin-bottom: 5px;

  text-align: center;

  color: #39bbdb;
}
.page-404 .outer .middle .inner .inner-detail {
  line-height: 1.4em;

  display: block;

  margin-bottom: 10px;

  text-align: center;

  color: #999999;
}
